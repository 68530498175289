import { TimeOption } from '@grafana/data';

import { ComboboxOption } from '../Combobox/Combobox';

export const quickOptions: TimeOption[] = [
  { from: 'now-5m', to: 'now', display: 'Последние 5 минут' },
  { from: 'now-15m', to: 'now', display: 'Последние 15 минут' },
  { from: 'now-30m', to: 'now', display: 'Последние 30 минут' },
  { from: 'now-1h', to: 'now', display: 'Последний час' },
  { from: 'now-3h', to: 'now', display: 'Последние 3 часа' },
  { from: 'now-6h', to: 'now', display: 'Последние 6 часов' },
  { from: 'now-12h', to: 'now', display: 'Последние 12 часов' },
  { from: 'now-24h', to: 'now', display: 'Последние 24 часа' },
  { from: 'now-2d', to: 'now', display: 'Последние 2 дня' },
  { from: 'now-7d', to: 'now', display: 'Последние 7 дней' },
  { from: 'now-30d', to: 'now', display: 'Последние 30 дней' },
  { from: 'now-90d', to: 'now', display: 'Последние 90 дней' },
  { from: 'now-6M', to: 'now', display: 'Последние 6 месяцев' },
  { from: 'now-1y', to: 'now', display: 'Последний 1 год' },
  { from: 'now-2y', to: 'now', display: 'Последние 2 года' },
  { from: 'now-5y', to: 'now', display: 'Последние 5 лет' },
  { from: 'now-1d/d', to: 'now-1d/d', display: 'Вчера' },
  { from: 'now-2d/d', to: 'now-2d/d', display: 'Позавчера' },
  { from: 'now-7d/d', to: 'now-7d/d', display: 'В этот день на прошлой неделе' },
  { from: 'now-1w/w', to: 'now-1w/w', display: 'Предыдущая неделя' },
  { from: 'now-1M/M', to: 'now-1M/M', display: 'Предыдущий месяц' },
  { from: 'now-1Q/fQ', to: 'now-1Q/fQ', display: 'Предыдущий финансовый квартал' },
  { from: 'now-1y/y', to: 'now-1y/y', display: 'Предыдущий год' },
  { from: 'now-1y/fy', to: 'now-1y/fy', display: 'Предыдущий финансовый год' },
  { from: 'now/d', to: 'now/d', display: 'Сегодня' },
  { from: 'now/d', to: 'now', display: 'Сегодня (до текущего момента)' },
  { from: 'now/w', to: 'now/w', display: 'Эта неделя' },
  { from: 'now/w', to: 'now', display: 'Эта неделя (до текущего момента)' },
  { from: 'now/M', to: 'now/M', display: 'Этот месяц' },
  { from: 'now/M', to: 'now', display: 'Этот месяц (до текущего момента)' },
  { from: 'now/y', to: 'now/y', display: 'Этот год' },
  { from: 'now/y', to: 'now', display: 'Этот год (до текущего момента)' },
  { from: 'now/fQ', to: 'now', display: 'Текущий финансовый квартал (до текущего момента)' },
  { from: 'now/fQ', to: 'now/fQ', display: 'Текущий финансовый квартал' },
  { from: 'now/fy', to: 'now', display: 'Текущий финансовый год (до текущего момента)' },
  { from: 'now/fy', to: 'now/fy', display: 'Текущий финансовый год' },
];

export const monthOptions: Array<ComboboxOption<number>> = [
  { label: 'Январь', value: 0 },
  { label: 'Февраль', value: 1 },
  { label: 'Март', value: 2 },
  { label: 'Апрель', value: 3 },
  { label: 'Май', value: 4 },
  { label: 'Июнь', value: 5 },
  { label: 'Июль', value: 6 },
  { label: 'Август', value: 7 },
  { label: 'Сентябрь', value: 8 },
  { label: 'Октябрь', value: 9 },
  { label: 'Ноябрь', value: 10 },
  { label: 'Декабрь', value: 11 },
];
